import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import SearchBar from '../SearchBar';

import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import AdCard from '../utils/AdCard';
import { Adsense } from '@ctrl/react-adsense';

const AdsList = () => {
  const [ads, setAds] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isProfessional, setIsProfessional] = useState(null);
  const [sortBy, setSortBy] = useState('recent');
  const [savedSearch, setSavedSearch] = useState(null);

  const { user: me, isAuthenticated, isAdmin } = useAuth();

  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queries = {
    mainType: searchParams.get('mainType') || '',
    productName: searchParams.get('productName') || '',
    city: searchParams.get('city') || '',
    postalCode: searchParams.get('postalCode') || '',
    type: searchParams.get('type') || '',
    coordinates: searchParams.get('coordinates') || '',
    distance: searchParams.get('distance') || ''
  }




  useEffect(() => {
    handleSearch(currentPage, queries);
    if (isAuthenticated) {
      fetchSavedSearches();
    }
  }, [currentPage, queries.productName, queries.city, queries.postalCode, queries.type, queries.coordinates, queries.distance, queries.mainType, isProfessional, sortBy, isAuthenticated]);

  const handleSearch = async (page = 1, queries) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/adverts/search`, {
        params: { queries, page, limit: 10, isProfessional, sortBy },
      });
      setAds(response.data.ads);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(page);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };


  const handlePageChange = (e, newPage) => {
    e.preventDefault();
    setCurrentPage(newPage);
  };





  const traslateMainType = (mainType) => {
    if (mainType === 'offer') {
      return 'Offre'
    }
    if (mainType === 'asked') {
      return 'Demande'
    }


  };

  const handleSaveSearch = async () => {

    if (savedSearch) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/search/${savedSearch._id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setSavedSearch(null);
        toast.success(`Recherche supprimée avec succès`, {
          position: toast.POSITION.TOP_CENTER
        });
      } catch (error) {
        console.error('Erreur lors de la suppression de la recherche :', error);
      }
    } else {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/search`, { query: queries }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setSavedSearch(response.data.query)
        toast.success(`Recherche enregistrée avec succès`, {
          position: toast.POSITION.TOP_CENTER
        });
      } catch (error) {
        console.error('Erreur lors de l\'enregistrement de la recherche :', error);
      }
    }
  };

  function sortedJsonStringify(obj) {
    if (typeof obj !== 'object' || obj === null) return JSON.stringify(obj);
    if (Array.isArray(obj)) return `[${obj.map(sortedJsonStringify).join(',')}]`;
    const keys = Object.keys(obj).sort();
    return `{${keys.map(key => `"${key}":${sortedJsonStringify(obj[key])}`).join(',')}}`;
  }

  async function fetchSavedSearches() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const savedSearches = response.data;
      const matchedSearch = savedSearches.find(({ query }) => sortedJsonStringify(query) === sortedJsonStringify(queries));
      setSavedSearch(matchedSearch);
    } catch (error) {
      console.error('Erreur lors de la récupération des recherches enregistrées:', error);
    }
  }



  const getSearchOptionText = () => {
    let str = '';
    const { mainType, productName, city, postalCode, type, coordinates, distance } = queries;

    if (mainType) {
      str += traslateMainType(mainType) + '; ';
    }
    if (productName) {
      str += productName + '; ';
    }
    if (city) {
      str += city + '; ';
    }
    if (postalCode) {
      str += postalCode + '; ';
    }
    if (type) {
      str += type + '; ';
    }

    if (distance) {
      str += 'Distance ' + distance + ' km' + '; ';
    }


    return str;
  }

  const getVisiblePages = (currentPage, totalPages) => {
    if (totalPages <= 4) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    // si la page actuelle est parmi les trois premières
    if (currentPage <= 3) {
      return [1, 2, 3, 4];
    }

    // si la page actuelle est parmi les trois dernières
    if (currentPage >= totalPages - 2) {
      return [totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    }

    // pour les autres pages, affichez la page actuelle au centre
    return [currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
  };

  const visiblePages = getVisiblePages(currentPage, totalPages);

  return (
    <div className="announcements-list">
      <SearchBar />
      <div className="announcements-container">
        <div className="filter-container">
          <select className='me-2' onChange={e => setIsProfessional(e.target.value)}>
            <option value="">Tout</option>
            <option value="true">Professionnel</option>
            <option value="false">Particulier</option>
          </select>
          <select onChange={e => setSortBy(e.target.value)}>
            <option value="desc">Plus récent</option>
            <option value="asc">Plus ancien</option>
          </select>
        </div>
        <div className="search-item">
          <div style={{ fontWeight: 'bold' }} className="text-bold">Résultat pour : <span style={{ fontWeight: 'normal' }}> {getSearchOptionText()}</span></div>
          {isAuthenticated && <span style={{ cursor: "pointer" }} onClick={handleSaveSearch}>{savedSearch && 'Ne plus suivre cette recherche'} <FontAwesomeIcon style={{ color: savedSearch ? '#C01E21' : '#0b9b4a' }} icon={savedSearch ? faTimes : faSave} /></span>}
        </div>

        {
          !ads.length && <div>
            <div style={{ fontSize: "1.5rem" }} className="announcements-empty mt-4"><FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: "0.5rem" }} />Aucun résultat trouvé ...</div>

          </div>
        }
        {ads.map((ad, index) => (
          <React.Fragment key={ad.id || index}>
            <AdCard ad={ad} />
            {(index + 1) % 3 === 0 && (
              <Adsense
                client="ca-pub-5061084407043738"
                slot="5343881586"
                layoutKey="-gu+1k+4y-4o-12"
                format="fluid"
                style={{ display: 'block' }}
              />
            )}
          </React.Fragment>
        ))}
      </div>
      {
        !!ads.length &&
        <div className="pagination">
          {currentPage > 1 && (
            <button onClick={(e) => handlePageChange(e, currentPage - 1)}>
              &lt;
            </button>
          )}
          {visiblePages[0] > 1 && <span>...</span>}
          {visiblePages.map((page) => (
            <button
              key={page}
              className={page === currentPage ? 'active' : ''}
              onClick={(e) => handlePageChange(e, page)}
            >
              {page}
            </button>
          ))}
          {visiblePages[visiblePages.length - 1] < totalPages && <span>...</span>}
          {currentPage < totalPages && (
            <button onClick={(e) => handlePageChange(e, currentPage + 1)}>
              &gt;
            </button>
          )}
        </div>
      }

    </div>
  );
};

export default AdsList;