import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

export default function AdCard({ ad, user, isAlert = false, removeAlert = () => { } }) {


    const navigate = useNavigate();

    const handleContact = (adId) => {
        navigate(`/messages/${adId}`);
    };

    const traslateAdType = (type) => {
        if (type === 'trade') {
            return 'Troc'
        }
        if (type === 'donation') {
            return 'Don'
        }
        if (type === 'sell') {
            return 'Vente'
        }

    };
    const translatePriceType = (priceType) => {
        if (priceType === 'gram') {
            return 'g';
        }
        if (priceType === 'unit') {
            return 'Pièce(s)';
        }
        if (priceType === 'box') {
            return 'Caisse(s)';
        }
        if (priceType === 'kilo') {
            return 'Kg';
        }
        if (priceType === 'liter') {
            return 'L';
        }
        if (priceType === 'basket') {
            return 'Panier(s)';
        }

    }


    return (
        <div key={ad._id} className="announcement-card">
            {isAlert && (
                <div className="alert-close-icon" onClick={() => removeAlert(ad._id)}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            )}
            {(user?.professional || ad?.user?.professional) && <span className="badge-pro">Pro</span>}
            <div className="announcement-card-image">
                {!!ad.images?.length && (
                    <Link to={`/ad/${ad._id}`}>
                        <img src={`${process.env.REACT_APP_API_URL}/${ad.images[0]}`} alt={ad.productName} />
                    </Link>
                )}
            </div>
            <div className="annoucement-card-info-container">
                <div className="announcement-card-info">

                    <div>
                        <h2 className="announcement-card-info-title">{ad.productName}</h2>
                        {ad.mainType !== 'asked' && <p className="annoucement-card-info-qte" >Quantité: <span className="announcement-card-info-bold">{ad.quantity}</span>{` ${translatePriceType(ad.priceType)}`}</p>}
                    </div>
                    <div>
                        <p className="announcement-card-info-bold">
                            {ad.location.city} ({ad.location.postalCode})
                        </p>
                        <p className="announcement-card-info-date">Le {new Date(ad.createdAt).toLocaleDateString()}</p>
                    </div>
                </div>
                <div className="announcement-card-actions">
                    <div>
                        {ad.mainType === 'asked' ?
                            <p className="announcement-card-actions-type">
                                Demande
                            </p>
                            :
                            <p className="announcement-card-actions-type">
                                {traslateAdType(ad.type)}
                            </p>
                        }
                        <p>

                            {ad.price && <span className="announcement-card-actions-price">{ad.price}  {ad.priceType === "kilo" ? "€/ kg" : "€"}</span>}

                        </p>
                    </div>
                    <div className="announcement-card-actions-buttons">
                        <button className="contact" onClick={() => handleContact(ad._id)}>
                            Contacter le vendeur
                        </button>
                        <Link to={`/ad/${ad._id}`}>
                            <button className="view">Voir l'annonce <span className="arrow"></span></button>
                        </Link>
                    </div>
                </div>
            </div>

        </div>

    )
}
