import axios from 'axios';
import React, { useEffect, useMemo } from 'react'
import { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import AdCard from '../utils/AdCard';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faTimes } from '@fortawesome/free-solid-svg-icons';

import './style.css';

function Alerts() {

    const [user, setUser] = useState({});
    const [ads, setAds] = useState([]);
    const [searches, setSearches] = useState([]);
    const { isAuthenticated, setIsAuthenticated } = useAuth();


    useEffect(() => {

        const fetchUserDataAndUpdateAlerts = async () => {
            const token = localStorage.getItem('token');
            try {
                // Fetch user data
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/user`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);

                // Update hasNewAlert to false
                await axios.put(`${process.env.REACT_APP_API_URL}/api/users/update`, {
                    hasNewAlert: false,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
            } catch (error) {
                console.error('Error fetching user data or updating alerts:', error);
            }
        };
        if (isAuthenticated) {
            fetchUserDataAndUpdateAlerts();
        }

    }, [isAuthenticated]);

    const removeAlert = async (adId) => {
        const token = localStorage.getItem('token');
        try {
            // Mettre à jour le tableau d'alertes en supprimant l'ID de l'annonce
            const updatedAlertAds = user.alertAds.filter(alertAd => alertAd._id !== adId).map(ad => ad._id);
            await axios.put(`${process.env.REACT_APP_API_URL}/api/users/update`, {
                alertAds: updatedAlertAds,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            setAds(user.alertAds.filter(alertAd => alertAd._id !== adId))
            toast.success(`Annonce supprimée avec succès`, {
                position: toast.POSITION.TOP_CENTER
            });


        } catch (error) {
            console.error('Erreur lors de la suppression de l\'alerte:', error);
        }
    };


    useEffect(() => {
        const fetchUserSearches = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setSearches(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des recherches de l\'utilisateur:', error);
            }
        };

        if (isAuthenticated) {
            fetchUserSearches();
        }

    }, [isAuthenticated]);

    const deleteSearch = async (searchId) => {
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/search/${searchId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setSearches(searches.filter(search => search._id !== searchId));
            toast.success('Recherche supprimée avec succès', {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (error) {
            console.error('Erreur lors de la suppression de la recherche:', error);
        }
    };


    const traslateMainType = (mainType) => {
        if (mainType === 'offer') {
            return 'Offre'
        }
        if (mainType === 'asked') {
            return 'Demande'
        }
    };

    const traslateAdType = (type) => {
        if (type === 'trade') {
            return 'Troc'
        }
        if (type === 'donation') {
            return 'Don'
        }
        if (type === 'sell') {
            return 'Vente'
        }

    };

    const getSearchOptionText = ({ query }) => {
        let str = '';
        const { mainType, productName, city, postalCode, type, coordinates, distance } = query;

        if (mainType) {
            str += traslateMainType(mainType) + '; ';
        }
        if (productName) {
            str += productName + '; ';
        }
        if (city) {
            str += city + '; ';
        }
        if (postalCode) {
            str += postalCode + '; ';
        }
        if (type) {
            str += traslateAdType(type) + '; ';
        }

        if (distance) {
            str += 'Distance ' + distance + ' km' + '; ';
        }


        return str;
    }



    useEffect(() => {
        setAds(user.alertAds || [])
    }, [user])

    return (
        <>
            <div className="home-title">Vos recherches <span className="text-boz">enregistrées</span> ... </div>


            <div className="announcements-container">
                {
                    searches.length
                        ?
                        searches.map((search) => (
                            <div key={search._id} className="search-item">
                                <div>{getSearchOptionText(search)}</div>
                                <FontAwesomeIcon className="search-item-icon" onClick={() => deleteSearch(search._id)} icon={faTimes} />
                            </div>
                        ))
                        :
                        <div>
                            <div style={{ fontSize: "1.5rem" }} className="announcements-empty"><FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: "0.5rem" }} />Aucune recherche trouvée ...</div>
                        </div>
                }
            </div>
            <div className="home-title">Vos dernières <span className="text-boz-red">alertes</span> ... </div>

            <div className="announcements-container">

                {
                    ads.length
                        ?
                        ads.map((ad) => (
                            <AdCard ad={ad} user={user} isAlert removeAlert={removeAlert} />
                        ))
                        :
                        <div>
                            <div style={{ fontSize: "1.5rem" }} className="announcements-empty"><FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: "0.5rem" }} />Aucune alerte trouvée ...</div>
                        </div>
                }

            </div>

        </>
    )
}

export default Alerts