import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AdDetails from './components/AdDetails';
import AddAdForm from './components/AddAdForm';

import AdsList from './components/AdsList';
import Footer from './components/Footer';
import ForgottenPassword from './components/ForgottenPassword';
import Header from './components/Header';
import HomePage from './components/HomePage';
import LoginForm from './components/LoginForm';
import MessagePage from './components/MessagePage';
import Profile from './components/Profile';
import ProfileEdit from './components/ProfileEdit';
import SignupForm from './components/SignupForm';
import SignupValidation from './components/SignupValidation';
import { useAuth } from './contexts/AuthContext';
import { Adsense } from '@ctrl/react-adsense';
import Alerts from './components/Alerts';
import SearchBar from './components/SearchBar';
import LatestAds from './components/LatestAds';

const App = () => {
  const {
    isAuthenticated,
    loading
  } = useAuth();

  const location = useLocation();

  useEffect(() => {
    if (location?.pathname.includes('/iframe')) {
      setInterval(() => window.parent.postMessage(document.body.offsetHeight, '*'), 500)
    }

    // Envoie la hauteur du document à la fenêtre parente

  }, [location])

  if (loading) {
    return <div>Loading...</div>;
  }




  if (location?.pathname === '/iframe') {

    document.getElementById('root').style.minHeight = "auto";
    return <>
      <Header iframe />
      <div className="main">
        <HomePage />
      </div>

    </>
  }


  if (location?.pathname === '/iframe1') {

    document.getElementById('root').style.minHeight = "auto";
    return <>
      <Header iframe />
    </>
  }

  if (location?.pathname === '/iframe2') {

    document.getElementById('root').style.minHeight = "auto";
    return <>
      <div className="main">
        <div className="home-container">
          <SearchBar />
        </div>
      </div>
    </>
  }

  if (location?.pathname === '/iframe3') {

    document.getElementById('root').style.minHeight = "auto";
    return <>
      <div className="main">
        <div className="home-container">
          <LatestAds />
        </div>
      </div>
    </>
  }


  return (
    <>
      <ToastContainer />
      <Header />
      <div className="main">
        <Routes>
          <Route path="/" element={<HomePage />} />

          <Route path="/alerts" element={<Alerts />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/signup/validate/:token" element={<SignupValidation />} />
          <Route path="/forgotten/password/reset/:token" element={<ForgottenPassword />} />
          <Route path="/forgotten/password" element={<ForgottenPassword />} />
          <Route path="/signup" element={<SignupForm />} />
          <Route path="/annonces" element={<AdsList />} />
          <Route
            path="/ad/add"
            element={isAuthenticated ? <AddAdForm /> : <Navigate to="/login" />}
          />
          <Route path="/edit-profile" element={<ProfileEdit />} />
          <Route path="/ad/:adId" element={<AdDetails />} />
          <Route path="/messages" element={isAuthenticated ? <MessagePage /> : <Navigate to="/login" />} />
          <Route path="/messages/:adId" element={isAuthenticated ? <MessagePage /> : <Navigate to="/login" />} />
          <Route path="/messages/:adId/:conversationId" element={isAuthenticated ? <MessagePage /> : <Navigate to="/login" />} />
          <Route path="/messages/direct/:conversationId" element={isAuthenticated ? <MessagePage /> : <Navigate to="/login" />} />
          <Route path="/profil/*" element={isAuthenticated ? <Profile /> : <Navigate to="/login" />} />
        </Routes>
      </div>

      <Footer />
    </>

  );
};

export default App;