import axios from 'axios';
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { readAndCompressImage } from 'browser-image-resizer';
import Spinner from 'react-bootstrap/Spinner';

import './style.css'

const AddAdForm = () => {

    const [mainType, setMainType] = useState('offer');
    const [type, setType] = useState('trade');
    const [productName, setProductName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [price, setPrice] = useState('');
    const [priceType, setPriceType] = useState('kilo');
    const [street, setStreet] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [images, setImages] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [description, setDescription] = useState('');
    const [imageError, setImageError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [useUserAddress, setUseUserAddress] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (useUserAddress) {
            fillAddressWithUser();
        }
    }, [useUserAddress]);

    const { user } = useAuth()
    const navigate = useNavigate();

    const fillAddressWithUser = () => {
        console.log('user', user);
        const address = user?.address;
        if (address) {
            setStreet(address.streetName);
            setCity(address.city);
            setPostalCode(address.postalCode);
            setStreetNumber(address.streetNumber);
        }
    };

    const handleImageChange = async (event) => {
        const files = Array.from(event.target.files).slice(0, 5);

        const config = {
            quality: 0.7,
            maxWidth: 800,
            maxHeight: 800,
            autoRotate: false,
            debug: true
        };

        for (let file of files) {
            try {
                const compressedFile = await readAndCompressImage(file, config);
                setImages((prevImages) => [...prevImages, compressedFile]);

                const reader = new FileReader();
                reader.onloadend = () => {
                    setImagePreviews((prevPreviews) => [...prevPreviews, reader.result]);
                };
                reader.readAsDataURL(compressedFile);
            } catch (error) {
                console.error("Error while compressing image:", error);
            }
        }
    };

    const removeImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
        setImagePreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (mainType !== 'asked' && images.length === 0) {
            setImageError(true);
            return;
        }

        const formData = new FormData();
        formData.append('mainType', mainType);
        formData.append('type', type);
        formData.append('productName', productName);
        formData.append('quantity', quantity);
        formData.append('price', price);
        formData.append('priceType', priceType);
        formData.append('description', description);
        formData.append('location', JSON.stringify({
            street,
            city,
            postalCode,
            streetNumber
        }));

        for (const file of images) {
            formData.append('images', file);
        }



        try {
            setIsSubmitting(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/adverts/create`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            if (response.status === 201) {
                setSuccess(true);
            }
        } catch (error) {
            console.error("Error while creating ad:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const getTraslate = () => {
        if (type === 'trade') {
            return 'troquer'
        }
        if (type === 'donation') {
            return 'donner'
        }

        return 'vendre'
    };

    const translatePriceType = () => {
        if (priceType === 'gram') {
            return 'gramme';
        }
        if (priceType === 'unit') {
            return 'pièce';
        }
        if (priceType === 'box') {
            return 'caisse';
        }
        if (priceType === 'kilo') {
            return 'kilo';
        }
        if (priceType === 'liter') {
            return 'litre'
        }
        if (priceType === 'basket') {
            return 'panier'
        }


    }

    return (
        <div className="add-ad-form-page">
            <div className="home-title">Quoi de <span className="text-boz">bon</span> dans  <span className="text-boz-red">votre potager</span> aujourd'hui ?</div>
            <div className="add-ad-form-container">
                {
                    !user.isActive ? (
                        <div className="add-ad-form-suspended">Votre compte est suspendu, vous ne pouvez pas créer d'annonce</div>
                    ) :

                        !success ?
                            <>
                                <form className="add-ad-form" onSubmit={handleSubmit}>
                                    <label>Vous souhaitez créer</label>
                                    <div>
                                        <div className="add-ad-form-radio-container">
                                            <label>
                                                <input
                                                    type="radio"
                                                    className="me-2"
                                                    name="mainType"
                                                    value="offer"
                                                    checked={mainType === 'offer'}
                                                    onChange={(e) => setMainType(e.target.value)}
                                                />
                                                Une offre
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    className="me-2"
                                                    name="mainType"
                                                    value="asked"
                                                    checked={mainType === 'asked'}
                                                    onChange={(e) => setMainType(e.target.value)}
                                                />
                                                Une demande
                                            </label>
                                        </div>
                                    </div>

                                    <label>Titre de l’annonce <span className='required-star'>*</span></label>
                                    <input
                                        type="text"
                                        value={productName}
                                        onChange={(e) => setProductName(e.target.value)}
                                        placeholder={mainType === 'asked' && "Que recherchez-vous ?"}
                                        required
                                    />
                                    <br />
                                    {
                                        mainType !== 'asked' && (
                                            <>
                                                <label>Type d'annonce <span className='required-star'>*</span></label>
                                                <select value={type} onChange={(e) => setType(e.target.value)} required>
                                                    <option value="trade">Troc</option>
                                                    <option value="sell">Vente</option>
                                                    <option value="donation">Don</option>
                                                </select>
                                                <br />
                                                <label>Quantité à {getTraslate()} <span className='required-star'>*</span></label>
                                                <div className="add-ad-form-quantity-container">
                                                    <input
                                                        type="number"
                                                        value={quantity}
                                                        onChange={(e) => setQuantity(e.target.value)}
                                                        required
                                                    />
                                                    <select value={priceType} onChange={(e) => setPriceType(e.target.value)} required>
                                                        <option value="kilo">Kilogramme	(kg)</option>
                                                        <option value="unit">Pièce</option>
                                                        <option value="gram">Gramme (g)</option>
                                                        <option value="box">Caisse</option>
                                                        <option value="liter">Litre (L)</option>
                                                        <option value="basket">Panier</option>

                                                    </select>
                                                </div>
                                                <br />
                                                {
                                                    type === 'sell' && (
                                                        <>
                                                            <label>Prix / {translatePriceType(priceType)}</label>
                                                            <input
                                                                type="number"
                                                                value={price}
                                                                onChange={(e) => setPrice(e.target.value)}
                                                                required
                                                            />

                                                            <br />
                                                        </>
                                                    )
                                                }
                                            </>
                                        )
                                    }

                                    <label>Description <span className='required-star'>*</span></label>
                                    <textarea
                                        rows={10}
                                        placeholder={mainType !== 'asked' && "Produits utilisés pour la culture, date de récolte, livraison possible, possibilité de troc, type d’agriculture..."}
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        required
                                    />

                                    <div>
                                        <div className="image-select-container">
                                            <label htmlFor="images">Image(s) du produit {mainType !== 'asked' && <span className='required-star'>*</span>}</label>
                                            {
                                                imagePreviews.length < 5 &&
                                                <input
                                                    type="file"
                                                    id="images"
                                                    name="images"
                                                    onChange={handleImageChange}
                                                    multiple
                                                    accept="image/*"
                                                />
                                            }

                                        </div>
                                        <div className="image-preview-container">
                                            {imagePreviews.map((preview, index) => (
                                                <div key={index} className="image-preview-container-item">
                                                    <img src={preview} alt="preview" width="100" />
                                                    <button type="button" onClick={() => removeImage(index)}>
                                                        -
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                        {imageError && <p className="image-error">Veuillez ajouter au moins une image.</p>}
                                    </div>


                                    <br />

                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="me-2"
                                                checked={useUserAddress}
                                                onChange={(e) => setUseUserAddress(e.target.checked)}
                                            />
                                            Utiliser ma ville et mon code postal
                                        </label>
                                    </div>

                                    <br />

                                    <label>Ville <span className='required-star'>*</span></label>
                                    <input
                                        type="text"
                                        value={city}
                                        disabled={useUserAddress}

                                        onChange={(e) => setCity(e.target.value)}
                                        required
                                    />
                                    <br />
                                    <label>Code postal <span className='required-star'>*</span></label>
                                    <input
                                        type="text"
                                        disabled={useUserAddress}
                                        value={postalCode}
                                        onChange={(e) => setPostalCode(e.target.value)}
                                        required
                                    />
                                    <br />
                                    <div>
                                        <label>J'accepte les <a className="cgu-link" href="https://jevendsmonpotager.fr/index.php/cgu/" target="_blank">conditions générales d'utilisation</a></label>
                                        <input
                                            type="checkbox"
                                            checked={termsAccepted}
                                            onChange={(e) => setTermsAccepted(e.target.checked)}
                                            style={{ marginLeft: '1rem' }}
                                            required
                                        />

                                    </div>

                                    <button className="button-green" type="submit">{isSubmitting ? <Spinner animation="border" /> : 'Publier'}</button>
                                </form>
                            </>
                            :
                            <>
                                <div className="add-anouncement-form-success">
                                    <p className="add-anouncement-form-success-b">Votre annonce a bien été prise en compte.</p>
                                    <br />
                                    <p className="add-anouncement-form-success-c">Elle apparaîtra sur le site quand elle aura été validée par un administrateur.</p>
                                    <p className="add-anouncement-form-success-d">Merci et à bientôt !</p>
                                    <button className="button-green" onClick={() => navigate('/')}>Retour à l'accueil</button>
                                </div>
                            </>

                }

            </div>
        </div >
    );
};

export default AddAdForm;